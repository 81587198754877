<template>
  <div class="container">
    <div v-if="isPaid" class="mt-5">
      <h1>Order Payment Success.</h1>
      <p class="text-success">
        Thank your for paying for your order. Please visit
        <router-link :to="{name: 'my_order', params: {id: order.id}}" class="text-primary">here</router-link> to check your order details.
      </p>
    </div>
    <div v-else class="mt-5">
      <h1>Order Payment Pending!</h1>
      <p class="text-warning">
        Your order is still awaiting payment confirmation.
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PaymentSuccessful",
  data() {
    return {
      order: null,
      isPaid: false
    };
  },
  mounted() {
    this.completeOrder();
  },
  methods: {
    getOrder() {
      this.isLoading = true;
      axios.get('orders/' + this.$route.params.id).then((response) => {
        this.order = response.data;
        this.isLoading = false;
        this.$loading(false);
      }).catch(() => {
        this.isLoading = false;
        this.$loading(false);
      });
    },

    completeOrder() {
      this.$loading(true);
      axios.post(`contipay/${this.$route.params.id}/confirm`, {}).then((response) => {
        const responseData = response.data;
        const isPaid = responseData.is_paid;
        if (isPaid) {
          this.isPaid = true;
          this.order = responseData.order;
          this.$loading(false);
        } else {
          setTimeout(this.completeOrder, 10000);
          this.$loading(false);
        }
      })
    }
  }
}
</script>